<template>
  <div class="input-wrapper rounded-lg pa-2">
    <v-row no-gutters>
      <v-col class="d-flex align-center justify-start">
        <label class="font-weight-bold font-size-16 mr-4">
          <slot />
        </label>
        <v-checkbox
          :disabled="true"
          label="Not applicable"
          dense
          hide-details
          class="small-checkbox mt-0"
          color="secondary"
          tabindex="-1"
        />
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col sm="5">
        <FormLabel :small="true"> Insulin-to-carb ratio </FormLabel>
        <ReviewField
          :display-value="item.insulinToCarbRatio"
          :is-dose-value="true"
        >
          <template #prepend
            ><strong class="small">1{{ dosingDisplayUnit }}:</strong></template
          >
          <template #append>g</template>
        </ReviewField>
      </v-col>
      <v-col sm="6" class="insulin-input">
        <FormLabel :small="true"> Insulin sensitivity factor </FormLabel>
        <ReviewField
          :display-value="item.insulinSensitivityFactor"
          :align-right="true"
        >
          <template #outer>
            <strong class="small text-no-wrap">{{
              bloodGlucoseUnitPerOneUtext
            }}</strong></template
          >
        </ReviewField>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FormLabel from '../shared/FormLabel'
import ReviewField from '../shared/ReviewField'
export default {
  name: 'CarbCountReviewField',
  components: { ReviewField, FormLabel },
  props: {
    item: { type: Object, required: true },
    bloodGlucoseUnit: { type: String, required: true },
    dosingDisplayUnit: { type: String, required: true },
  },
  computed: {
    bloodGlucoseUnitPerOneUtext() {
      return this.bloodGlucoseUnit
        ? `${this.bloodGlucoseUnit} : 1U`
        : `--/-- : 1U`
    },
  },
}
</script>

<style lang="scss" scoped>
.small,
.small-checkbox ::v-deep .v-label {
  font-size: 12px;
  margin-top: 0;
}

.input-wrapper {
  background-color: $nn-TB_T98;
}

.carb-input {
  ::v-deep .v-input__slot {
    background-color: white;
  }
}

.insulin-input {
  ::v-deep .v-input__slot {
    background-color: white;
  }
  ::v-deep .v-input__append-outer {
    font-size: 12px;
    line-height: 18px;
  }
}

.disabled {
  color: $nn-D_T60;
}
</style>
