<template>
  <div data-testid="bolus-dose-section">
    <v-row>
      <v-col>
        <h5>Enter carb counting factors</h5>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mb-3">
        <FormLabel :large="true">1. Select unit for blood glucose </FormLabel>
        <div class="d-flex align-center justify-start">
          <ReviewField
            :dense="true"
            :display-value="prescription.bloodGlucoseUnit"
            :is-radio-button="true"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <FormLabel :large="true"
          >2. Enter insulin-to-carb ratio and insulin sensitivity factor
        </FormLabel>
      </v-col>
      <v-col v-if="prescription.bolus_breakfast.enabled" sm="4" class="pt-0">
        <CarbCountReviewField
          :item="prescription.bolus_breakfast"
          :blood-glucose-unit="prescription.bloodGlucoseUnit"
          :dosing-display-unit="dosingDisplayUnit('bolus_breakfast')"
        >
          {{ $t('time-point-short.bolus_breakfast') }}
        </CarbCountReviewField>
      </v-col>

      <v-col v-if="prescription.bolus_lunch.enabled" sm="4" class="pt-0">
        <CarbCountReviewField
          :item="prescription.bolus_lunch"
          :blood-glucose-unit="prescription.bloodGlucoseUnit"
          :dosing-display-unit="dosingDisplayUnit('bolus_lunch')"
        >
          {{ $t('time-point-short.bolus_lunch') }}
        </CarbCountReviewField>
      </v-col>

      <v-col v-if="prescription.bolus_dinner.enabled" sm="4" class="pt-0">
        <CarbCountReviewField
          :item="prescription.bolus_dinner"
          :blood-glucose-unit="prescription.bloodGlucoseUnit"
          :dosing-display-unit="dosingDisplayUnit('bolus_dinner')"
        >
          {{ $t('time-point-short.bolus_dinner') }}
        </CarbCountReviewField>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import initialPrescriptionMixin from './initialPrescriptionMixin'
import ReviewField from '../shared/ReviewField'
import CarbCountReviewField from './_CarbCountReviewField'
import FormLabel from '../shared/FormLabel'

export default {
  name: 'BolusCarbCountOverview',
  components: { FormLabel, CarbCountReviewField, ReviewField },
  mixins: [initialPrescriptionMixin],
}
</script>
